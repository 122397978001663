.main-menu .list-md ion-item ion-img {
    width: 24px;
    height: 24px;
}

.main-menu ion-button.in-toolbar {
    --background: transparent;
}

/* Light Mode */
@media (prefers-color-scheme: light) {

    .main-menu ion-list ion-icon {
        color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.9);
    }

    .main-menu ion-button.in-toolbar {
        --ion-toolbar-background: var(--color);
        --color: var(--ion-color-light);
    }

    .main-menu ion-toolbar.menu-heander-logo {
        --ion-toolbar-background: var(--ion-color-primary, #163056);
    }

    .main-menu ion-toolbar.menu-footer-logo {
        --ion-toolbar-background: var(--ion-color-primary, #163056);
        --color: var(--ion-color-light);
    }
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {

    .main-menu ion-list ion-icon {
        color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.9);
    }

    .main-menu ion-button.in-toolbar {
        --ion-toolbar-background: var(--color);
        --color: var(--ion-color-tertiary);
    }

    .main-menu ion-toolbar.menu-heander-logo {
        --ion-toolbar-background: var(--ion-color-primary, #163056);
    }

    .main-menu ion-toolbar.menu-footer-logo {
        --ion-toolbar-background: var(--ion-color-primary, #163056);
    }
}

/* PC Screen */
@media only screen and (min-width: 1024px) {
    ion-split-pane {
        --side-min-width: 15rem !important; /* Update this as per your requirements */
        --side-max-width: 15% !important;
    }

    .main-menu ion-header ion-toolbar {
        --min-height: 65px;
    }

    .main-menu .logo-img {
        margin-left: 1rem;
    }

    .main-menu {
        border: none;
    }

    ion-toolbar.toolbar-segment,
    ion-content.main-content-wrapper {
        border-left: var(--custom-border);
    }

    @media (prefers-color-scheme: dark) {
        ion-toolbar.toolbar-segment,
        ion-content.main-content-wrapper {
            --custom-border: 1px solid var(--ion-color-step-100, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
        }
    }
}
