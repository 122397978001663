/** Menu bar color correction **/
/*ion-menu > *, ion-menu ion-item > *, ion-menu-toggle > *, ion-menu ion-content > *, ion-menu ion-list > * {
    --background: var(--ion-color-primary);
}

ion-menu ion-footer ion-toolbar {
    --ion-toolbar-background: var(--ion-color-primary);
}

ion-menu .list-md {
    --background: var(--ion-color-primary);
    background: var(--ion-color-primary);
}

.background-color-primary {
    --ion-background-color: var(--ion-color-primary) !important;
    --background: var(--ion-color-primary, #163056);
}

.menuText {
    --color: var(--ion-color-text-secondary) !important;
}*/

:root {
    --ion-background-color: var(--ion-color-secondary);
    --ion-background-color-rgb: var(--ion-color-secondary-rgb);
    --ion-text-color: var(--ion-color-text-primary);
}

.logo-img {
    width: 9.75rem;
    padding-bottom: 0.75rem;
    padding-top: 1rem;
}

ion-button {
    --box-shadow: none;
}

.textPrimary {
    color: var(--ion-color-text-primary);
}

.textSecondary {
    color: var(--ion-color-text-secondary);
}

.texttertiary {
    color: var(--ion-color-text-tertiary);
}

.removeInnerBorderWidth {
    --inner-border-width: 0px;
}
