/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #163056;
    --ion-color-primary-rgb: 22,48,86;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #132a4c;
    --ion-color-primary-tint: #2d4567;
    /** secondary **/
    --ion-color-secondary: #F1F5F8;
    --ion-color-secondary-rgb: 241,245,248;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0,0,0;
    --ion-color-secondary-shade: #d4d8da;
    --ion-color-secondary-tint: #f2f6f9;
    /** tertiary **/
    --ion-color-tertiary: #fafbfc;
    --ion-color-tertiary-rgb: 250,251,252;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0,0,0;
    --ion-color-tertiary-shade: #dcddde;
    --ion-color-tertiary-tint: #fbfbfc;
    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;
    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;
    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;
    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;
    /** light **/
    --ion-color-light: #fafbfc;
    --ion-color-light-rgb: 250,251,252;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
    /** Text Colors **/
    --ion-color-text-primary: #F1F5F8;
    --ion-color-text-secondary: #5D78FF;
    --ion-color-text-tertiary: #919191;
    /** Slide Buttons **/
    --ion-color-found: #83c27a;
    --ion-color-found-rgb: 131,194,122;
    --ion-color-found-contrast: #ffffff;
    --ion-color-found-contrast-rgb: 0,0,0;
    --ion-color-found-shade: #73ab6b;
    --ion-color-found-tint: #8fc887;
    --ion-color-notfound: #cc7a3e;
    --ion-color-notfound-rgb: 204,122,62;
    --ion-color-notfound-contrast: #ffffff;
    --ion-color-notfound-contrast-rgb: 0,0,0;
    --ion-color-notfound-shade: #b46b37;
    --ion-color-notfound-tint: #d18751;
    --ion-color-discontinued: #aa3a3a;
    --ion-color-discontinued-rgb: 170,58,58;
    --ion-color-discontinued-contrast: #ffffff;
    --ion-color-discontinued-contrast-rgb: 255,255,255;
    --ion-color-discontinued-shade: #963333;
    --ion-color-discontinued-tint: #b34e4e;
    --ion-color-seasonal: #8243b8;
    --ion-color-seasonal-rgb: 130,67,184;
    --ion-color-seasonal-contrast: #ffffff;
    --ion-color-seasonal-contrast-rgb: 255,255,255;
    --ion-color-seasonal-shade: #723ba2;
    --ion-color-seasonal-tint: #8f56bf;
    --ion-color-comment: #335990;
    --ion-color-comment-rgb: 51,89,144;
    --ion-color-comment-contrast: #ffffff;
    --ion-color-comment-contrast-rgb: 255,255,255;
    --ion-color-comment-shade: #2d4e7f;
    --ion-color-comment-tint: #476a9b;
}

.ion-color-found {
    --ion-color-base: var(--ion-color-found);
    --ion-color-base-rgb: var(--ion-color-found-rgb);
    --ion-color-contrast: var(--ion-color-found-contrast);
    --ion-color-contrast-rgb: var(--ion-color-found-contrast-rgb);
    --ion-color-shade: var(--ion-color-found-shade);
    --ion-color-tint: var(--ion-color-found-tint);
}

.ion-color-notfound {
    --ion-color-base: var(--ion-color-notfound);
    --ion-color-base-rgb: var(--ion-color-notfound-rgb);
    --ion-color-contrast: var(--ion-color-notfound-contrast);
    --ion-color-contrast-rgb: var(--ion-color-notfound-contrast-rgb);
    --ion-color-shade: var(--ion-color-notfound-shade);
    --ion-color-tint: var(--ion-color-notfound-tint);
}

.ion-color-discontinued {
    --ion-color-base: var(--ion-color-discontinued);
    --ion-color-base-rgb: var(--ion-color-discontinued-rgb);
    --ion-color-contrast: var(--ion-color-discontinued-contrast);
    --ion-color-contrast-rgb: var(--ion-color-discontinued-contrast-rgb);
    --ion-color-shade: var(--ion-color-discontinued-shade);
    --ion-color-tint: var(--ion-color-discontinued-tint);
}

.ion-color-seasonal {
    --ion-color-base: var(--ion-color-seasonal);
    --ion-color-base-rgb: var(--ion-color-seasonal-rgb);
    --ion-color-contrast: var(--ion-color-seasonal-contrast);
    --ion-color-contrast-rgb: var(--ion-color-seasonal-contrast-rgb);
    --ion-color-shade: var(--ion-color-seasonal-shade);
    --ion-color-tint: var(--ion-color-seasonal-tint);
}

.ion-color-comment {
    --ion-color-base: var(--ion-color-comment);
    --ion-color-base-rgb: var(--ion-color-comment-rgb);
    --ion-color-contrast: var(--ion-color-comment-contrast);
    --ion-color-contrast-rgb: var(--ion-color-comment-contrast-rgb);
    --ion-color-shade: var(--ion-color-comment-shade);
    --ion-color-tint: var(--ion-color-comment-tint);
}

@media (prefers-color-scheme: light) {
    body {
        --ion-color-primary: var(--ion-color-primary);
        --ion-color-primary-rgb: var(--ion-color-primary-rgb);
        --ion-color-primary-contrast: var(--ion-color-primary-contrast);
        --ion-color-primary-contrast-rgb: var(--ion-color-primary-contrast-rgb);
        --ion-color-primary-shade: var(--ion-color-primary-shade);
        --ion-color-primary-tint: var(--ion-color-primary-tint);
        --ion-color-secondary: var(--ion-color-secondary);
        --ion-color-secondary-rgb: var(--ion-color-secondary-rgb);
        --ion-color-secondary-contrast: var(--ion-color-secondary-contrast);
        --ion-color-secondary-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
        --ion-color-secondary-shade: var(--ion-color-secondary-shade);
        --ion-color-secondary-tint: var(--ion-color-secondary-tint);
        --ion-color-tertiary: var(--ion-color-tertiary);
        --ion-color-tertiary-rgb: var(--ion-color-tertiary-rgb);
        --ion-color-tertiary-contrast: var(--ion-color-tertiary-contrast);
        --ion-color-tertiary-contrast-rgb: var(--ion-color-tertiary-contrast-rgb);
        --ion-color-tertiary-shade: var(--ion-color-tertiary-shade);
        --ion-color-tertiary-tint: var(--ion-color-tertiary-tint);
        --ion-card-background: var(--ion-color-tertiary, #fafbfc);
    }

    /*
        Original text color: 
        --ion-text-color: #919191;
        --ion-text-color-rgb: 145,145,145;
    */

    .md body {
        --ion-background-color: #F1F5F8;
        --ion-background-color-rgb: 241,245,248;
        --ion-text-color: #505050;
        --ion-text-color-rgb: 80,80,80;
    }

    .ios body {
        --ion-background-color: #F1F5F8;
        --ion-background-color-rgb: 241,245,248;
        --ion-text-color: #505050;
        --ion-text-color-rgb: 80,80,80;
    }
}

@media (prefers-color-scheme: dark) {
    body {
        --ion-color-primary: var(--ion-color-primary);
        --ion-color-primary-rgb: var(--ion-color-primary-rgb);
        --ion-color-primary-contrast: var(--ion-color-primary-contrast);
        --ion-color-primary-contrast-rgb: var(--ion-color-primary-contrast-rgb);
        --ion-color-primary-shade: var(--ion-color-primary-shade);
        --ion-color-primary-tint: var(--ion-color-primary-tint);
        --ion-color-secondary: var(--ion-color-secondary);
        --ion-color-secondary-rgb: var(--ion-color-secondary-rgb);
        --ion-color-secondary-contrast: var(--ion-color-secondary-contrast);
        --ion-color-secondary-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
        --ion-color-secondary-shade: var(--ion-color-secondary-shade);
        --ion-color-secondary-tint: var(--ion-color-secondary-tint);
        --ion-color-tertiary: var(--ion-color-tertiary);
        --ion-color-tertiary-rgb: var(--ion-color-tertiary-rgb);
        --ion-color-tertiary-contrast: var(--ion-color-tertiary-contrast);
        --ion-color-tertiary-contrast-rgb: var(--ion-color-tertiary-contrast-rgb);
        --ion-color-tertiary-shade: var(--ion-color-tertiary-shade);
        --ion-color-tertiary-tint: var(--ion-color-tertiary-tint);
        --ion-card-background: var(--ion-color-step-100, #2A2A2A);
    }

    /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

    .md body {
        --ion-background-color: var(--ion-color-step-50, #121212);
        --ion-background-color-rgb: 13,13,13;
        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;
        --ion-border-color: #222222;
        --ion-color-step-50: #1e1e1e;
        --ion-color-step-100: #2a2a2a;
        --ion-color-step-150: #363636;
        --ion-color-step-200: #414141;
        --ion-color-step-250: #4d4d4d;
        --ion-color-step-300: #595959;
        --ion-color-step-350: #656565;
        --ion-color-step-400: #717171;
        --ion-color-step-450: #7d7d7d;
        --ion-color-step-500: #898989;
        --ion-color-step-550: #949494;
        --ion-color-step-600: #a0a0a0;
        --ion-color-step-650: #acacac;
        --ion-color-step-700: #b8b8b8;
        --ion-color-step-750: #c4c4c4;
        --ion-color-step-800: #d0d0d0;
        --ion-color-step-850: #dbdbdb;
        --ion-color-step-900: #e7e7e7;
        --ion-color-step-950: #f3f3f3;
        --ion-item-background: #1e1e1e;
        --ion-toolbar-background: #1f1f1f;
        --ion-tab-bar-background: #1f1f1f;
    }

    /*
   * iOS Dark Theme
   * -------------------------------------------
   */

    .ios body {
        --ion-background-color: var(--ion-color-step-50, #121212);
        --ion-background-color-rgb: 0, 0, 0;
        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;
        --ion-color-step-50: #1e1e1e;
        --ion-color-step-100: #2a2a2a;
        --ion-color-step-150: #363636;
        --ion-color-step-200: #414141;
        --ion-color-step-250: #4d4d4d;
        --ion-color-step-300: #595959;
        --ion-color-step-350: #656565;
        --ion-color-step-400: #717171;
        --ion-color-step-450: #7d7d7d;
        --ion-color-step-500: #898989;
        --ion-color-step-550: #949494;
        --ion-color-step-600: #a0a0a0;
        --ion-color-step-650: #acacac;
        --ion-color-step-700: #b8b8b8;
        --ion-color-step-750: #c4c4c4;
        --ion-color-step-800: #d0d0d0;
        --ion-color-step-850: #dbdbdb;
        --ion-color-step-900: #e7e7e7;
        --ion-color-step-950: #f3f3f3;
        --ion-item-background: #000000;
    }
}
