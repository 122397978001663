#bg {
    height: 40%;
    width: 100%;
    background: var(--ion-color-dark) url('../../public/assets/login/bg_02.jpg') no-repeat center center;
    border-radius: 0 0 35% 40%;
    position: relative;
    background-size: cover;
    position: relative;
}

    #bg ion-img {
        width: 10rem;
        position: absolute;
        bottom: -7.25vw;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

.login-container ion-list {
    margin-top: 4.5rem !important;
}

    .login-container ion-list ion-list-header {
        font-weight: 600;
        font-size: 1.5rem;
        text-align: center;
    }

    .login-container ion-list ion-item {
        border-radius: 20px;
    }

    .login-container ion-list p {
        font-size: 0.8rem;
        padding-left: 5vw;
    }

    .login-container ion-list div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

        .login-container ion-list div ion-button {
            width: 40%;
            margin: 20px 0;
            text-transform: capitalize;
            --box-shadow: none;
            --background: #2467f6;
            --border-radius: 10px;
            --padding-top: 25px;
            --padding-bottom: 25px;
        }

.removeInnerBorderWidth {
    --inner-border-width: 0px;
}

@media only screen and (max-width: 800px) {
    #bg {
        height: 25%;
        border-radius: 0 0 0 0;
    }

        #bg ion-img {
            bottom: -12.25vw;
        }
}


@media (prefers-color-scheme: dark) {
    .login-container ion-list ion-item {
        --background: var(--ion-color-step-100, #fafbfc);
        -webkit-box-shadow: 0 4px 3px -4px var(--ion-color-step-100);
        -moz-box-shadow: 0 4px 3px -4px var(--ion-color-step-100);
        box-shadow: 0 4px 3px -4px var(--ion-color-step-100);
    }
}

@media (prefers-color-scheme: light) {
    .login-container ion-list ion-item {
        --rgb-prime: 36, 103, 246;
        --background: rgba(var(--rgb-prime, 0, 0, 0), 0.05);
        --color: #000;
        -webkit-box-shadow: 0 4px 3px -4px var(--ion-color-step-100);
        -moz-box-shadow: 0 4px 3px -4px var(--ion-color-step-100);
        box-shadow: 0 4px 3px -4px var(--ion-color-step-100);
    }
}
