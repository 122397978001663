.container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}

.container strong {
    font-size: 10px;
    line-height: 16px;
}

.container p {
    font-size: 10px;
    line-height: 12px;
}
