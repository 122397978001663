.btnActionImages {
    height: 2.5rem;
    width: 2.5rem;
}

.custom-input-alert h2 {
    text-align: center;
    justify-items: center;
    font-size: 16px !important;
}

@media (prefers-color-scheme: light) {
    .custom-input-alert h2 {
        --ion-text-color: var(--ion-color-tertiary-contrast);
    }
}
