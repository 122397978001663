.notifications-empty-container {
    padding: 1rem;
}

.notifications-empty-container-inner {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
}

    .notifications-empty-container-inner strong {
        font-size: 15px;
        line-height: 16px;
    }

    .notifications-empty-container-inner p {
        font-size: 13px;
        line-height: 12px;
    }
