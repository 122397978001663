
.homePage ion-card {
    width: 100%;
}


/*.homePage ion-list {
    padding-top:0;
}*/

.empty-container-item {
    padding: 1rem;
}

.empty-container-inner {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
}

.empty-containeqr-inner strong {
    font-size: 15px;
    line-height: 16px;
}

.empty-container-inner p {
    font-size: 13px;
    line-height: 12px;
}

.child-background-transperent {
    --background: transparent;
}


@media (prefers-color-scheme: light) {

    .toolbar-segment {
        --ion-toolbar-segment-color: var(--ion-item-color, var(--ion-text-color, #000));
    }

        .toolbar-segment ion-segment ion-segment-button {
            --color: var(--ion-toolbar-segment-color, rgba(var(--ion-text-color-rgb, 0, 0, 0), 1));
        }

    .homePage ion-card ion-item-sliding ion-item {
        --background: var(--ion-color-tertiary, #fafbfc);
    }
}


@media (prefers-color-scheme: dark) {

    .toolbar-segment {
        --ion-toolbar-segment-color: var(--ion-item-color, var(--ion-text-color, #000));
    }

        .toolbar-segment ion-segment ion-segment-button {
            --color: var(--ion-toolbar-segment-color, rgba(var(--ion-text-color-rgb, 0, 0, 0), 1));
        }

    .homePage ion-card ion-item-sliding ion-item {
        --background: var(--ion-color-step-100, #2A2A2A);
    }
}
