.app-page-header ion-toolbar {
    --ion-toolbar-background: var(--ion-color-primary, #163056);
}

.app-page-header {
    --ion-toolbar-color: var(--ion-color-tertiary, #fafbfc);
}

@media only screen and (min-width: 1024px) {
    .app-page-header ion-toolbar {
        --min-height: 65px;
    }

    .app-page-header .logo-img {
        display: none;
    }
}
